import React, { useState } from "react"
import Button from "./Button"
import LangSwitch from "./LangSwitch"
import Link from "../contentfulModels/Link"

import apple from "../images/appl.png"
import android from "../images/androi.png"

import "./Header.scss"
import "./Hamburger.scss"

const Header = ({ indexPage, dispatch, data, language }) => {
  const [hamburgerToggle, setHamburgerToggle] = useState(false)
  const [headerBackground, setHeaderBackground] = useState(false)

  const onHamburgerToggle = () => {
    setHamburgerToggle(!hamburgerToggle)
  }

  if (typeof document !== "undefined") {
    hamburgerToggle
      ? document.body.classList.add("lock")
      : document.body.classList.remove("lock")
  }

  const onHeaderBackground = () => {
    window.scrollY >= 150
      ? setHeaderBackground(true)
      : setHeaderBackground(false)
  }

  if (typeof document !== "undefined") {
    window.addEventListener("scroll", onHeaderBackground)
  }

  return (
    <header className={`header ${headerBackground ? "overlay" : ""}`}>
      <div className="header__container">
        <Link href={""}>
          <img
            src={data?.logo?.file?.url}
            loading="lazy"
            alt="Logo"
            className="header__logo"
          />
        </Link>

        <nav
          className={"header__navigation" + (hamburgerToggle ? " active" : "")}
        >
          <ul className="header__navigation_list">
            {data?.navigationLinks.map(({ name, url }) => (
              <Link
                key={url}
                className="header__navigation_list_link"
                href={url}
                indexPage={indexPage}
              >
                {name}
              </Link>
            ))}
          </ul>
          <Button styleName="transparent" href={data?.button?.url}>
            <img
              style={{ maxHeight: "29px", minHeight: "22px", minWidth: "20px" }}
              src={apple}
            ></img>
          </Button>
          <Button styleName="transparent" href={data?.buttonAndroid?.url}>
            <img
              style={{ maxHeight: "29px", minHeight: "22px", minWidth: "20px" }}
              src={android}
            ></img>
          </Button>
          <Button styleName="transparent" href="/preorder">
            Order
          </Button>
          {/* <LangSwitch dispatch={dispatch} language={language} /> */}
        </nav>
        <div className="hamburger-outter" style={{ marginTop: "10px" }}>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/hamburgers/1.1.3/hamburgers.min.css"
            integrity="sha512-+mlclc5Q/eHs49oIOCxnnENudJWuNqX5AogCiqRBgKnpoplPzETg2fkgBFVC6WYUVxYYljuxPNG8RE7yBy1K+g=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <button
            className={
              "hamburger hamburger--vortex" +
              (hamburgerToggle ? " is-active" : "")
            }
            type="button"
            onClick={onHamburgerToggle}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
