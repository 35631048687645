import React from "react"

import "./Card.scss"

const Card = ({ onClick, activeCardIndex, index, title, children }) => {
  return (
    <div
      onClick={onClick}
      onKeyPress={onClick}
      role={"tab"}
      tabIndex={0}
      className={`card ${activeCardIndex === index ? "active" : ""}`}
    >
      <hr className="card__line-vertical" />
      <div className="card__container">
        <h3 className="card__title">{title}</h3>
        <p className="card__text">{children}</p>
      </div>
    </div>
  )
}

export default Card
