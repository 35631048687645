import React from "react"

import "./Background.scss"

const Background = ({ videoType, videoSrc }) => {
  return (
    <>
      {(
        <video
          preload="auto"
          no-controls="true"
          autoPlay={true}
          loop={true}
          playsInline={true}
          muted={true}
        >
          <track kind="captions" />
          <source src={videoSrc} type={videoType} />
        </video>
      )}
    </>
  )
}

export default Background
