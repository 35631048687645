import React, { useState } from "react"
import Card from "../contentfulModels/Card"

import "./SectionRetailers.scss"

const SectionRetailers = ({ data }) => {
  const [activeCard, setActiveCard] = useState(0)

  return (
    <section
      id={data?.header?.navigationLinks[3].url.toLowerCase().replace("#", "")}
    >
      <div className="retailers">
        <div className="_container">
          <div className="retailers__title section-title">
            <h6 className="section-title-h6">{data?.forUsers?.sectionName}</h6>
            <h1 className="section-title-h1">
              {data?.forUsers?.sectionTitle.sectionTitle}
              <br />
            </h1>
            <div className="line"></div>
          </div>
        </div>
        <div className="retailers__content">
          <div className="retailers__content_cards">
            {data?.forUsers?.sectionCard.map(({ title, text }, index) => (
              <Card
                key={index}
                title={title}
                onClick={() => setActiveCard(index)}
                activeCardIndex={activeCard}
                index={index}
              >
                {text.text}
              </Card>
            ))}
          </div>
          <div className="retailers__content_right">
            {data?.forUsers?.sectionCard.map(
              ({ image, textOverImage }, index) => (
                <img
                  key={index}
                  className={`retailers__content_right_image ${
                    index === (activeCard || 0) ? "active" : ""
                  }`}
                  src={image.file.url}
                  sizes="(max-width: 479px) 90vw, (max-width: 767px) 71vw, (max-width: 991px) 483.5625px, (max-width: 1439px) 483.578125px, 483.5625px"
                  alt={textOverImage}
                />
              )
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionRetailers
