import React from "react"
import Header from "../components/Header"
import SectionHome from "../components/SectionHome"
import SectionAboutUs from "../components/SectionAboutUs"
import SectionBrands from "../components/SectionBrands"
import SectionRetailers from "../components/SectionRetailers"
import SectionContact from "../components/SectionContact"
import Footer from "../components/Footer"
import { IndexQuery } from "../components/Query/IndexPage"
import { connect } from "react-redux"

import "./index-clear.scss"
import "./index.scss"

const IndexPage = ({ language, dispatch }) => {
  const data = IndexQuery()

  let dataLang = {}
  let gymsDataLang = []
  let usersDataLang = []

  data.allContentfulIndex.nodes.forEach(node => {
    if (language === node.node_locale) {
      dataLang = node
    }
  })

  data.allContentfulSectionForGyms.nodes.forEach(node => {
    if (language === node.node_locale) {
      gymsDataLang.push(node)
    }
  })

  data.allContentfulSectionForUsers.nodes.forEach(node => {
    if (language === node.node_locale) {
      usersDataLang.push(node)
    }
  })

  return (
    <>
      <Header
        indexPage={true}
        data={dataLang.header}
        dispatch={dispatch}
        language={language}
      />
      <main className="content">
        <SectionHome data={dataLang} />
        <SectionAboutUs data={dataLang} />

        {gymsDataLang?.reverse().map((el, index) => <SectionBrands key={index} data={{ forGyms: el }} />)}
        {usersDataLang?.reverse().map((el, index) => <SectionRetailers key={index} data={{ forUsers: el }} />)}

        <SectionContact data={dataLang} language={language} />
      </main>
      <Footer data={dataLang.footer} />
    </>
  )
}

export default connect(state => state.languageReducer, null)(IndexPage)
