import React from "react"
import Form from "./Form"
import Button from "./Button"
import { useForm } from "@formspree/react"
import { toast } from "react-toastify"
import BackgroundStatic from "./BackgroundStatic"

import "react-toastify/dist/ReactToastify.min.css"

import "./SectionContact.scss"

const SectionContact = ({ data, language }) => {
  const [state, handleSubmit] = useForm("mlezjvjw")

  const notify = () =>
    toast.success(
      language === "en-US"
        ? "Thank you for feedback!"
        : language === "zh"
        ? "感謝您的反饋！"
        : "Thank you for feedback!",
      {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    )

  return (
    <section
      className="contact"
      // id={data?.header?.navigationLinks[4].url.toLowerCase().replace("#", "")}
    >
      <div className="contact__container background-video">
        <BackgroundStatic
          // videoType={data?.contact?.backgroundVideo.file.contentType}
          // videoSrc={data?.contact?.backgroundVideo.file.url}
          imageType={data?.contact?.backgroundImage.file.contentType}
          imageSrc={data?.contact?.backgroundImage.file.url}
        ></BackgroundStatic>
        <div className="contact__title section-title">
          <h6 className="section-title-h6">{data?.contact?.sectionName}</h6>
          <h1 className="section-title-h1">
            {data?.contact?.sectionTitle.sectionTitle}
            <br />
          </h1>
          <p className="contact__title_text">
            {data?.contact?.sectionText.sectionText}
          </p>
          <div className="line"></div>
        </div>
        <div className="contact__form">
          <form onSubmit={handleSubmit}>
            <div className="contact__form_grid">
              <Form placeholder="Name" required={true} />
              
              <Form type="email" placeholder="Email" required={true} />
            
              <div className="input message">
                <label className="input__label" htmlFor="message">
                  Message
                </label>
                <textarea
                  className="input__textarea input__input"
                  maxLength={5000}
                  name="Message"
                  placeholder="Message"
                  required
                />
              </div>
            </div>
            <Button
              type="submit"
              disabled={state.submitting}
              href={data?.contact?.buttonLink?.url}
              toastify={true}
              stateSubmitting={state.submitting}
              notify={notify}
            >
              {data?.contact?.buttonLink?.name}
            </Button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default SectionContact
