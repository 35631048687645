import React, { useEffect, useState } from "react"
import Button from "./Button"
import Background from "./Background"

import "./SectionHome.scss"

const SectionHome = ({ data }) => {
  const [currentWidth, setCurrentWidth] = useState(0)
  
  useEffect(() => {
    setCurrentWidth(document.body.offsetWidth)
  }, [])
  

  const handleRedirect = url => {
    const link = document.createElement("a")
    link.setAttribute("href", url)
    link.click()
    link.remove()
  }

 // useEffect(() => {
 //  setCurrentWidth(document.body.clientWidth)
 // }, [document.body.offsetWidth])



  return (
    <section
      className="home"
      id={data?.header?.navigationLinks[0].url.toLowerCase().replace("#", "")}
    >
      <div className="home__section background-video">
        <Background
          videoType={
            currentWidth < 480 && data?.home?.backgroundVideoMobile
              ? data?.home?.backgroundVideoMobile.file.contentType
              : data?.home?.backgroundVideo.file.contentType
          }
          videoSrc={
            currentWidth < 480 && data?.home?.backgroundVideoMobile
              ? data?.home?.backgroundVideoMobile.file.url
              : data?.home?.backgroundVideo.file.url
          }
        />
        <div className="home__section_container">
          <div className="home__section_container_title">
            <h1 className="home__section_container_firstTitle">
              {data?.home?.title1stRow}
              <br />
              <span>
                {data?.home?.title2ndRowBlue}
                <br />
                {data?.home?.title3dRow}
              </span>
            </h1>
          </div>
          <p className="home__section_container_text">
            {data?.home?.textUnderTitle}
          </p>
          <a href={data?.home?.buttonLink?.url}>
            <Button href={data?.home?.buttonLink?.url}>
              {data?.home?.buttonLink?.name}
            </Button>
          </a>
        </div>
      </div>
    </section>
  )
}

export default SectionHome
