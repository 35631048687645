import React from "react"
import BackgroundStatic from "./BackgroundStatic"

import "./SectionAboutUs.scss"

const SectionAboutUs = ({ data }) => {
  return (
    <section
      className="about-us"
      id={data?.header?.navigationLinks[1].url.toLowerCase().replace("#", "")}
    >
      <div className="about-us__section background-video">
      <BackgroundStatic
          // videoType={data?.about?.backgroundVideo.file.contentType}
          // videoSrc={data?.about?.backgroundVideo.file.url}
          imageType={data?.about?.backgroundImage.file.contentType}
          imageSrc={data?.about?.backgroundImage.file.url}
        ></BackgroundStatic>
        <div className="about-us__section_container">
          <div className="about-us__section_container_left">
            <h6 className="section-title-h6">{data?.about?.sectionName}</h6>
            <h1 className="section-title-h1">
              {data?.about?.sectionTitle.sectionTitle}
              <br />
              <span className="blue">{data?.about?.sectionTitleBlue}</span>
            </h1>
            <hr className="line" />
          </div>
          <div className="about-us__section_container_right">
            <div className="about-us__section_container_right_text">
              <p>{data?.about?.sectionContent.sectionContent}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAboutUs
